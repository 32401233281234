<template>
  <div class="choiceness" @click="hidePayImg">
    <div class="choicenessCont">
      <dl v-for="(item, index) in articleList" :key="index">
        <dt>{{ item.title }}</dt>
        <dd>
          <div @click.stop="setPayImgTop(item.top)" class="payBtn">
            支付{{ item.money }}元阅读
          </div>
        </dd>
      </dl>
      <div class="payImg" :style="{ top: payImgTop }">
        <img src="../assets/imgs/pay_img.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Choiceness",
  setup() {
    const payImgTop = ref();
    const articleList = [
      {
        title: "2021国内工具应用趋势分析",
        money: "99",
        top: "-7%",
      },
      {
        title: "如何重塑工具类应用的价值，提升个人和社会效率",
        money: "99",
        top: "7%",
      },
      {
        title: "移动互联网下半场：我们的机遇与挑战",
        money: "99",
        top: "22%",
      },
      {
        title: "效率应用的商业化的现状",
        money: "99",
        top: "32%",
      },
      {
        title: "国内效率/工具类应用能否破局，攻坚海外市场",
        money: "99",
        top: "45%",
      },
    ];
    const setPayImgTop = (data) => {
      if (data == payImgTop.value) {
        payImgTop.value = "-700px";
      } else {
        payImgTop.value = data;
      }
    };
    const hidePayImg = ()=>{
        payImgTop.value = "-700px";
    }
    return {
      articleList,
      payImgTop,
      setPayImgTop,
      hidePayImg
    };
  },
  methods: {},
  mounted(){
    $('.mainCont').css({"borderBottom":"1px solid #EFF3FF"})
  },
  beforeRouteLeave(){
    $('.mainCont').css({"borderBottom":"none"})
  }
};
</script>
<style lang="scss" scoped>
.choiceness {
  margin-top: 54px;
}
.choicenessCont {
  position: relative;
  height: 100%;
  width: 1269px;
  background-color: #fff;
  box-shadow: 0px 7px 71px 0px rgba(160, 165, 193, 0.2);
  border-radius: 12px;
  margin: 0 auto;
  dl {
    padding: 40px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 68px;
    margin: 0;
    &:not(:first-child) {
      border-top: 1px solid #e0e0e0;
    }
    dt {
      font-size: 20px;
      color: #192646;
      line-height: 2;
      font-weight: normal;
      width: 900px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    dd {
      margin: 0;
      .payBtn {
        border-radius: 6px;
        color: #fff;
        background-color: #5679f9;
        display: inline-block;
        line-height: 34px;
        font-size: 14px;
        text-align: center;
        padding: 2px 20px;
        cursor: pointer;
      }
    }
  }
}
.payImg {
  position: absolute;
  width: 300px;
  top: -700px;
  right: 152px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
